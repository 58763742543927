
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//authtable routes
			{
				path: '/authtable/:fieldName?/:fieldValue?',
				name: 'authtablelist',
				component: () => import('./pages/authtable/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/authtable/view/:id', 
				name: 'authtableview', 
				component: () => import('./pages/authtable/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'authtableuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'authtableaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'authtableaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/authtable/add', 
				name: 'authtableadd', 
				component: () => import('./pages/authtable/add.vue'), 
				props: true
			},
	
			{ 
				path: '/authtable/edit/:id', 
				name: 'authtableedit', 
				component: () => import('./pages/authtable/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/authtable/edittoken/:id', 
				name: 'authtableedittoken', 
				component: () => import('./pages/authtable/edittoken.vue'), 
				props: true
			},
		

//bussinesunits routes

//clients routes
			{
				path: '/clients/:fieldName?/:fieldValue?',
				name: 'clientslist',
				component: () => import('./pages/clients/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clients/view/:id', 
				name: 'clientsview', 
				component: () => import('./pages/clients/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clients/add', 
				name: 'clientsadd', 
				component: () => import('./pages/clients/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clients/edit/:id', 
				name: 'clientsedit', 
				component: () => import('./pages/clients/edit.vue'), 
				props: true
			},
		

//cotizaciones routes
			{
				path: '/cotizaciones/:fieldName?/:fieldValue?',
				name: 'cotizacioneslist',
				component: () => import('./pages/cotizaciones/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/cotizaciones/view/:id', 
				name: 'cotizacionesview', 
				component: () => import('./pages/cotizaciones/view.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/add', 
				name: 'cotizacionesadd', 
				component: () => import('./pages/cotizaciones/add.vue'), 
				props: true
			},
	
			{ 
				path: '/cotizaciones/edit/:id', 
				name: 'cotizacionesedit', 
				component: () => import('./pages/cotizaciones/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/editdetails/:id', 
				name: 'cotizacioneseditdetails', 
				component: () => import('./pages/cotizaciones/editdetails.vue'), 
				props: true
			},
		
			{ 
				path: '/cotizaciones/addparty/:id', 
				name: 'cotizacionesaddparty', 
				component: () => import('./pages/cotizaciones/addparty.vue'), 
				props: true
			},
		

//months routes

//partys routes
			{
				path: '/partys/:fieldName?/:fieldValue?',
				name: 'partyslist',
				component: () => import('./pages/partys/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/partys/view/:id', 
				name: 'partysview', 
				component: () => import('./pages/partys/view.vue'), 
				props: true
			},
		
			{ 
				path: '/partys/add', 
				name: 'partysadd', 
				component: () => import('./pages/partys/add.vue'), 
				props: true
			},
	
			{ 
				path: '/partys/edit/:id', 
				name: 'partysedit', 
				component: () => import('./pages/partys/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/partys/addparty', 
				name: 'partysaddparty', 
				component: () => import('./pages/partys/addparty.vue'), 
				props: true
			},
	

//permissions routes

//places routes

//projects routes

//recordatorios routes

//roles routes

//taxtable routes

//type routes

//types routes

//year routes

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
